import React from 'react';
import logoimg from "../../assets/logo/favicon.png"

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8" id='contacts'>
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="mb-4">
          <img src={logoimg} alt="Company Logo" className="h-26 w-auto mb-2" />
            <h4 className="text-lg font-bold mb-2"></h4>
            <p className="text-sm text-gray-300"></p>
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-bold mb-2">Habarlashmak</h4>
            <p className="text-sm text-gray-300">Telefon: 800-522 6-72-27</p>
            <p className="text-sm text-gray-300">Telefon: +993 522 7-27-99</p>
            <p className="text-sm text-gray-300">Telefon: +99356463466</p>
            <p className="text-sm text-gray-300">Telefon: +99356463466</p>
            <p className="text-sm text-gray-300">Email: b_tex_complex@mail.ru</p>
          </div>

          <div className="mb-4">
            <h4 className="text-lg font-bold mb-2">Habarlara gosulmak</h4>
            <form className="flex">
              <input type="email" placeholder="Email address" className="bg-gray-700 text-white px-3 py-2 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500" />
              <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">Abuna ýazylyň</button>
            </form>
          </div>
        </div>
        <hr className="my-6 border-gray-600" />
        <p className="text-sm text-center text-gray-400">&copy; 2024 Turkmen Tekstil Company. All rights reserved.</p>

      </div>
    </footer>
  );
};

export default Footer;
