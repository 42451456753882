import React from 'react';
import { useTranslation } from 'react-i18next';
import image1 from "../../assets/catalog/tazeonumler/img_3.jpg";
import image2 from "../../assets/catalog/tazeonumler/img_2.jpg";
import image3 from "../../assets/catalog/tazeonumler/img_1.jpg";

const CardContainer = () => {
  const { t } = useTranslation();

  const cards = [
    {
      image: image1,
      title: t("newProducts.highQualityProducts"),
    },
    {
      image: image2,
      title: t("newProducts.giftSets"),
    },
    {
      image: image3,
      title: t("newProducts.newProducts"),
    }
  ];

  return (
    <div className="mt-10">
      <h2 className="text-center text-3xl font-bold mb-6">{t("newProducts.title")}</h2>
      <div className="flex flex-wrap justify-center">
        {cards.map((card, index) => (
          <div key={index} className="max-w-sm rounded-xl overflow-hidden shadow-lg m-4 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 transition duration-300 transform hover:shadow-xl">
            <img src={card.image} alt={card.title} className="w-full h-48 object-cover" />
            <div className="p-4">
              <h3 className="text-xl font-semibold mb-2">{card.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardContainer;