import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import axios from 'axios';
import '../../index.css';

const BannerCarousel = () => {
  const [banners, setBanners] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'tm'); // Здесь устанавливаем начальный язык

  useEffect(() => {
    // axios.get(`http://localhost:8000/api/banners/?language=${language}`)
    axios.get(`https://bayramalytextilex.com.tm/api/banners/?language=${language}`)

      .then(response => {
        // console.log(response.data); // Проверить данные в консоли
        setBanners(response.data);
      })
      .catch(error => console.error('Error fetching banners:', error));
  }, [language]); // Добавляем language в зависимости useEffect для перезагрузки при изменении языка

  const handleChangeLanguage = (lng) => {
    setLanguage(lng);
    localStorage.setItem('language', lng);
  };

  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      autoplay={{ delay: 3000 }}
      loop={true}
      modules={[Navigation, Pagination, Autoplay]}
      className="swiper-container"
    >
      {banners.map(banner => (
        <SwiperSlide key={banner.id}>
          <div className="banner-slide">
            <img src={banner.image} alt={banner[`title_${language}`]} />
            <div className="banner-caption">
              <h2>{banner[`title_${language}`]}</h2>
              <p>{banner[`description_${language}`]}</p>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BannerCarousel;