import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';  // Импортируйте Footer

const AllNews = () => {
  const { t, i18n } = useTranslation();
  const [news, setNews] = useState([]);

  useEffect(() => {
    axios.get('https://bayramalytextilex.com.tm/api/news/')
      .then(response => setNews(response.data))
      .catch(error => console.error(error));
  }, []);

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    if (text.length <= maxLength) {
      return text;
    }
    return text.substr(0, maxLength) + '...';
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex-grow p-4 bg-white mb-24" id='all-news'>
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">{t('navigation.all_news')}</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-14 lg:mx-10 my-12">
          {news.map((newsItem) => (
            <div key={newsItem.id} className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200">
              <img src={newsItem.image} alt={newsItem[`title_${i18n.language}`]} className="w-full h-64 object-cover rounded-t-lg" />
              <div className="p-4">
                <h4 className="text-lg font-semibold text-gray-800 mb-2">{newsItem[`title_${i18n.language}`]}</h4>
                <p className="text-gray-600 mb-2 text-sm">{truncateText(newsItem[`description_${i18n.language}`], 120)}</p>
                <p className="text-gray-500 text-sm">{newsItem.date_published}</p>
                <Link to={`/news/${newsItem.id}`} className="mt-4 block text-blue-600 hover:text-blue-700 font-semibold">
                  {t('news.read_more')}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllNews;