import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Header from '../Header/Header'; // Adjust path as needed
import Footer from '../Footer/Footer'; // Adjust path as needed

const AllProducts = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    axios.get('https://bayramalytextilex.com.tm/api/products/')
      .then(response => setProducts(response.data))
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  return (
    <div>
      <Header />
      <div className="p-12 lg:mx-12" id='all-products'>
        <h2 className="text-3xl font-semibold mb-6 text-center">{t('products.our_products')}</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
          {products.map(product => (
            <div key={product.id} className="bg-white p-4 rounded-xl shadow-lg">
              <img src={product.image} alt={product[`title_${localStorage.getItem('language')}`]} className="w-full h-64 object-cover mb-4 rounded-lg" />
              <h4 className="text-lg font-semibold mb-2">{product[`title_${localStorage.getItem('language')}`]}</h4>
              <p className="text-gray-700 mb-4">{product[`description_${localStorage.getItem('language')}`]}</p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllProducts;