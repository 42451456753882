import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const NewsDetail = () => {
    const { id } = useParams();
    const { t, i18n } = useTranslation();
    const [news, setNews] = useState(null);
    const [relatedNews, setRelatedNews] = useState([]);

    useEffect(() => {
        // Fetch the specific news item
        axios.get(`https://bayramalytextilex.com.tm/api/news/${id}/`)
            .then(response => {
                setNews(response.data);
                // Fetch related news
                return axios.get('https://bayramalytextilex.com.tm/api/news/');
            })
            .then(response => {
                setRelatedNews(response.data.filter(item => item.id !== id).slice(0, 5));
            })
            .catch(error => {
                console.error('Error fetching news:', error);
            });
    }, [id]);

    if (!news) return <div className="flex items-center justify-center h-screen">{t('Loading...')}</div>;

    const title = news[`title_${i18n.language}`] || news.title_en || '';
    const description = news[`description_${i18n.language}`] || news.description_en || '';

    return (
        <>
            <Header />
            <div className="bg-white min-h-screen py-12">
                <div className="max-w-6xl mx-auto flex flex-col lg:flex-row lg:space-x-8">
                    {/* Main News Section */}
                    <div className="flex-1 bg-white shadow-xl rounded-lg overflow-hidden">
                        <img src={news.image} alt={title} className="w-full h-72 object-cover rounded-t-lg" />
                        <div className="p-8">
                            <h1 className="text-4xl font-bold text-gray-800 mb-4">{title}</h1>
                            <p className="text-gray-700 text-lg leading-relaxed mb-6">{description}</p>
                            <p className="text-gray-600 text-sm">{news.date_published}</p>
                        </div>
                    </div>
                    {/* Related News Section */}
                    <div className="lg:w-96 mt-10 lg:mt-0 bg-white shadow-xl rounded-lg p-6">
                        <h2 className="text-2xl font-bold mb-4 text-gray-800">{t('news.related')}</h2>
                        <div className="space-y-6  h-[800px] overflow-y-auto">
                            {relatedNews.map(item => (
                                <div key={item.id} className="flex items-start space-x-4 border-b pb-2">
                                    <img src={item.image} alt={item[`title_${i18n.language}`]} className="w-[50%] h-16 object-cover rounded-md" />
                                    <div className="flex flex-col w-full">
                                        <p className="text-sm font-semibold text-gray-800 mb-1">{item[`title_${i18n.language}`]}</p>
                                        <span className="text-gray-600 text-sm mb-2">{item.date_published}</span>
                                        <Link to={`/news/${item.id}`} className="text-blue-600 hover:text-blue-700 font-semibold">
                                            {t('news.read_more')}
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default NewsDetail;