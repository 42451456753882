import React, { useState } from 'react';
import poster from "../../assets/banners/about2.jpg"; // Убедитесь, что путь указан правильно

const VideoPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h2 className="text-2xl font-bold mb-4 text-center text-gray-800"></h2>
      <div className="relative" style={{ paddingTop: '56.25%' }}>
        {!isPlaying ? (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-50 ">
            <button
              className="text-white text-3xl font-bold z-10 "
              onClick={handlePlay}
            >
              ▶️
            </button>
            {/* Выводим изображение-превью для проверки */}
            <img src={poster} alt="Video Poster" className="absolute inset-0 w-full h-full object-cover rounded-2xl" />
          </div>
        ) : (
          <video
            controls
            className="absolute inset-0 w-full h-full"
            poster={poster} // Используем импортированное изображение
          >
            <source src="https://bayramalytextilex.com.tm/media/videos/merw_OH8jQNY.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;