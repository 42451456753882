import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Products = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    axios.get('https://bayramalytextilex.com.tm/api/products/')
      .then(response => setProducts(response.data))
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  return (
    <div className="p-8 lg:mx-12" id='products'>
      <h2 className="text-3xl font-semibold mb-6 text-center">{t('products.our_products')}</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
        {products.slice(0, 5).map(product => (
          <div key={product.id} className="bg-white p-4 rounded-xl shadow-md">
            <img src={product.image} alt={product[`title_${localStorage.getItem('language')}`]} className="w-full h-64 object-cover mb-4 rounded-lg" />
            <h4 className="text-lg font-semibold mb-2">{product[`title_${localStorage.getItem('language')}`]}</h4>
            <p className="text-gray-700 mb-4">{product[`description_${localStorage.getItem('language')}`]}</p>
          </div>
        ))}
      </div>
      <div className="text-center mt-8">
        <Link to="/allproducts" className="text-blue-600 hover:text-blue-700 font-semibold">
          {t('products.view_all')}
        </Link>
      </div>
    </div>
  );
};

export default Products;