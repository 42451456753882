import React from 'react';
// import image1 from "../../assets/blog/b-1.jpg";
import image2 from "../../assets/banners/about1.jpg";
import image3 from "../../assets/banners/about2.jpg";

const AboutUs = () => {
  return (
    <div id='about' className="flex flex-wrap items-center justify-around p-8 md:p-20 bg-white">
      <div className="w-full lg:w-2/3 xl:w-2/3 mb-8 md:mb-0 md:mr-8">
        <h2 className="text-3xl font-bold mb-6">Biz barada</h2>
        <p className="text-lg text-gray-700 leading-relaxed mb-6">
          Baýramaly şäherindäki Beýik Saparmyrat Türkmenbaşy adyndaky dokma toplumy Türkmenistanyň Prezidentiniň
          1997-nji ýylyň 30-njy oktýabrynda № 3372 belgili karary esasynda (Beýik Britaniýa) Kotam Enterpraýzis
          LTD kompaniýasy bilen Dokma senagaty ministrliginiň arasyndaky şertnama esasynda 1-nji tapgyry 2002-nji ýylyň
          fewral aýynda we 2-nji tapgyry 2005-nji ýylyň fewral aýynda gurulyp ulanmaga berilen. Toplumyň umumy tutýan
          meýdany 16,0 gektara deň, şol sanda desgalaryň tutýan meýdany 2,64 gektara deň. Häzirki wagtda dokma toplumy
          müşderileriň buýurmalaryna görä 30*30,30*50,40*70,<br />50*90,70*140,100*180,150*210,<br />180*210 ölçeglerdäki
          polotensalar, sütükli çaga,aýal,erkek hammam halatlary we polotensa toplumlary öndürilýär.
        </p>
        <h2 className="text-3xl font-bold mb-6">Dokma toplumynyň önümçiligi 4-bölümden ybarat.</h2>
        <h2 className="text-3xl font-semibold mb-6">1.Ýüplük bölümi</h2>
        <p className="text-lg text-gray-700 leading-relaxed mb-6">
          Bu bölümde 32-sany (Italiýanyň Marzoli, Germaniýanyň Sthlafhorst we Truzsсhler firmalarynyň) enjamlary işleýär,
          öndürýän önümleri Nm14/1, Nm17/1, Nm20/1, Nm27/1, Nm34/1 nah ýüplükleri.
        </p>
        <h2 className="text-3xl font-semibold mb-6">2.Dokma bölümi</h2>
        <p className="text-lg text-gray-700 leading-relaxed mb-6">
          Bu bölümde 59-sany (Italiýanyň Ramallumin, Vamatex, Promatech Leonardo, Itema we Germaniýanyň KARL MAYER
          Sthlafhorst firmalarynyň)enjam işleýär, öndürýän önümleri çig nah tüýjimek mata.
        </p>
        <h2 className="text-3xl font-semibold mb-6">3.Boýag bölümi</h2>
        <p className="text-lg text-gray-700 leading-relaxed mb-6">
          Bu bölümde 16-sany (Italiýa MСS MF, UITECh Rama, Corina firmalarynyň) enjam işleýär,
          çig tüýjimek matalar müşderileriň isleglerine görä dürli reňklerde boýalýar.
        </p>
        <h2 className="text-3xl font-semibold mb-6">4.Tikinçilik bölümi</h2>
        <p className="text-lg text-gray-700 leading-relaxed mb-6">
          Bu bölümde 66-sany (Italiýanyň Magetron, Ýaponiýanyň Barudan, Siruba firmalarynyň)enjam işleýär,
          dürli ölçegdäki polotensalar, aýal,erkek,çaga hammam halatlary,dürli görnüşdäki aşhana toplumlary öndürilýär.
          Dokma toplumynyň önümçiliginiň esasy çig maly bolan pagta süými welaýatlarymyzyň pagta arassalaýjy kärhanalaryndan alynýar.
          Dokma toplumynda häzirki wagtda dünýä standartlaryna doly gabat gelip, öndürilen önümler özara ylalaşyk şertnamalar
          esasynda daşary ýurt bazarlaryna ugradylýar.
        </p>
      </div>
      <div className="w-full lg:w-1/3 xl:w-2/3 flex flex-col items-center justify-center">
        {/* <img src={image1} alt="Image 1" className="w-full rounded-lg shadow-lg mb-8" /> */}
        <img src={image2} alt="Image 2" className="w-full rounded-lg shadow-lg mb-8" />
        {/* <img src={image3} alt="Image 3" className="w-full rounded-lg shadow-lg mb-8" /> */}
      </div>
    </div>
  );
};

export default AboutUs;
