import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules'; // Изменённый путь для импортов

// Import images for new products
import newProduct1 from '../../assets/catalog/tazeonumler/img_1.jpg';
import newProduct2 from '../../assets/catalog/tazeonumler/img_2.jpg';
import newProduct3 from '../../assets/catalog/tazeonumler/img_3.jpg';
import newProduct4 from '../../assets/catalog/tazeonumler/img_4.jpg';
import newProduct5 from '../../assets/catalog/tazeonumler/img_5.jpg';
import newProduct6 from '../../assets/catalog/tazeonumler/img_6.jpg';

const NewProductsSlider = () => {
  return (
    <div className='lg:mx-24 px-1'>
      <Swiper
        spaceBetween={50}
        navigation={{ 
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        pagination={{ clickable: true }}
        loop={true}
        modules={[Navigation, Pagination]} // Исправленное использование модулей
        className="swiper-container"
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50
          }
        }}
      >
        <SwiperSlide>

          <div className="flex justify-center">
            <img src={newProduct1} alt="New Product 1" className="max-h-96" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center">
            <img src={newProduct2} alt="New Product 2" className="max-h-96" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center">
            <img src={newProduct3} alt="New Product 3" className="max-h-96" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center">
            <img src={newProduct4} alt="New Product 4" className="max-h-96" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center">
            <img src={newProduct5} alt="New Product 5" className="max-h-96" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center">
            <img src={newProduct6} alt="New Product 6" className="max-h-96" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default NewProductsSlider;