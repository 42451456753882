import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './i18n';  // Импортируйте i18n для инициализации
import NewsDetail from './components/NewsDetails/NewsDetail';
import AllNews from './components/AllNews/Allnews';
import AllProducts from './components/AllProducts/Allproducts';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/news/:id" element={<NewsDetail />} />
        <Route path="/allnews" element={<AllNews />} />
        <Route path="/allproducts" element={<AllProducts />} />

      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();