import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logoimg from "../../assets/logo/favicon.png";
import flagTM from "../../assets/flags/tk1.png";
import flagEN from "../../assets/flags/en1.png";
import flagRU from "../../assets/flags/ru1.png";
import { Link } from 'react-router-dom';

export default function Header() {
  const { t, i18n } = useTranslation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  useEffect(() => {
    const currentLanguage = localStorage.getItem('language') || 'tm';
    i18n.changeLanguage(currentLanguage);
  }, [i18n]);

  const navigation = [
    { name: t('navigation.main'), href: '/' },
    { name: t('navigation.aboutus'), href: '#about' },
    { name: t('navigation.products'), href: '#products' },
    { name: t('navigation.news'), href: '#news' },
    { name: t('navigation.contacts'), href: '#contacts' },
  ];

  const handleNavigationClick = (e, href) => {
    e.preventDefault();
    
    if (href === '/') {
      // Redirect to main page
      window.location.href = href;
    } else {
      // Scroll to the corresponding section
      const element = document.getElementById(href.substring(1)); // Remove #
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      setMobileMenuOpen(false);
    }
  };

  return (
    <header className="bg-white/99 opacity-100 backdrop-blur-2xl z-20 sticky top-0 shadow-md rounded-2xl">
      <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Tekstil App</span>
            <img alt="" src={logoimg} className="h-11 w-auto" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="text-md font-semibold leading-6 text-gray-900 cursor-pointer"
              onClick={(e) => handleNavigationClick(e, item.href)}
            >
              {item.name}
            </Link>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
          <button onClick={() => changeLanguage('tm')} className="mx-2">
            <img src={flagTM} alt="Turkmen Flag" className="h-6 w-6" />
          </button>
          <button onClick={() => changeLanguage('en')} className="mx-2">
            <img src={flagEN} alt="English Flag" className="h-6 w-6" />
          </button>
          <button onClick={() => changeLanguage('ru')} className="mx-2">
            <img src={flagRU} alt="Russian Flag" className="h-6 w-6" />
          </button>
        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-4 py-12 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Textil</span>
              <img alt="" src={logoimg} className="h-8 w-auto" />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer"
                    onClick={(e) => handleNavigationClick(e, item.href)}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="py-4">
                <div className="mt-12 flex justify-center">
                  <button onClick={() => changeLanguage('tm')} className="mx-2">
                    <img src={flagTM} alt="Turkmen Flag" className="h-6 w-6" />
                  </button>
                  <button onClick={() => changeLanguage('en')} className="mx-2">
                    <img src={flagEN} alt="English Flag" className="h-6 w-6" />
                  </button>
                  <button onClick={() => changeLanguage('ru')} className="mx-2">
                    <img src={flagRU} alt="Russian Flag" className="h-6 w-6" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}