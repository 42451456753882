import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import BannerCarousel from './components/BannerCarousel/BannerCarousel';
import CardContainer from './components/CardContainer/CardContaier';
import AboutUs from './components/AboutUs/Aboutus';
import NewProductsSlider from './components/NewProductsSlider/NewProductsSlider';
import ScrollToTop from './components/ScroolToTop/ScroolToTop';
import Products from './components/Products/Products';
import NewsComponent from './components/NewsComponent/NewsComponent';
import Footer from './components/Footer/Footer';
import ContactForm from './components/ContactForm/ContactForm';
import AllNews from './components/AllNews/Allnews';
import VIdeomodal from "./components/Videomodal/Videomodal"

function App() {
    return (

            <div className="App">
                <Header />
                <Routes>
                    <Route path="*" element={
                        <>
                            <BannerCarousel id="banner" />
                            <CardContainer id="card-container" />
                            <VIdeomodal />
                            <AboutUs id="about" />
                            <NewProductsSlider id="new-products" />
                            <ScrollToTop />
                            <Products id="products" />
                            <NewsComponent id="news" />
                            {/* <AllNews id="allnews"/> */}

                            {/* <ContactForm id="contacts" /> */}
                        </>
                    } />
                </Routes>
                <Footer />
            </div>

    );
}

export default App;